<script setup lang="ts">
import type { UserType } from 'lc-services/types'

defineOptions({
  inheritAttrs: false,
})
const props = defineProps<{
  inputEmail: string
  isEmailSignUp: boolean
  sourcePage: 'modal' | 'full_page'
}>()

const emits = defineEmits<{
  'on-go-back': [{ navigation: string }]
  'on-success-code': [
    { navigation: string; email: string; firstName: string; lastName: string },
  ]
  'on-update-token': [string]
  'handle-redirect': []
}>()

const { $api, $lcServicesAuth } = useNuxtApp()
const { authController, authPresenter } = $lcServicesAuth
const { login } = useAuth()
const { t } = useI18n()
const { trackEvent } = useTracking()

const token = ref('')
const errors = ref<unknown[]>([])

const isLoading = ref(false)
const handleResponse = async (
  authUser: UserType | null,
  navigation: string | null,
) => {
  if (navigation === 'App' && authUser?.incompleteSignup === false) {
    const { data } = await $api.v1.auth.validateToken()
    await login(data)
    trackEvent({ event: 'log_in', log_in_page: props.sourcePage })
    emits('handle-redirect')
  } else if (navigation === 'SignUp' || navigation === 'UserPhoneUpdate') {
    emits('on-success-code', {
      navigation,
      email: props.inputEmail,
      firstName: '',
      lastName: '',
    })
  }
}
const mutation = async () => {
  isLoading.value = true
  emits('on-update-token', token.value)

  try {
    await authController.verifyToken({
      email: props.inputEmail,
      loginToken: !props.isEmailSignUp ? token.value : undefined,
      emailToken: props.isEmailSignUp ? token.value : undefined,
    })
    await handleResponse(
      authPresenter.vm.verifyTokenResponse,
      authPresenter.vm.navigation,
    )

    if (authPresenter.vm.errors?.length) {
      errors.value = authPresenter.vm.errors
    }
  } finally {
    isLoading.value = false
  }
}

const isSendNewTokenBtnDisabled = ref(false)
const sendNewTokenErrors = ref('')
const sendNewToken = async () => {
  await authController.sendTokenByEmail({
    email: props.inputEmail,
    locale: 'fr',
  })
  if (authPresenter.vm.errors?.[0]?.error === 'locked') {
    sendNewTokenErrors.value = 'locked'
  }
  isSendNewTokenBtnDisabled.value = true

  setTimeout(() => {
    isSendNewTokenBtnDisabled.value = false
  }, 6000)
}

watch(token, (newVal) => {
  if (newVal.length === 6) setTimeout(mutation, 1000)
})
</script>

<template>
  <div class="w-full">
    <div>
      <button
        class="mb-4 flex"
        type="button"
        @click="emits('on-go-back', { navigation: 'Email' })"
      >
        <BaseIcon
          class="mb-4"
          :name="$device.isMobile ? 'navArrowLeft' : 'arrowLeft'"
        />
        <span v-if="$device.isMobile" class="ml-1 font-medium">{{
          $t('account.back')
        }}</span>
      </button>
      <I18nT keypath="auth.enterCode" tag="p" class="mb-6">
        <template #email>
          <span class="block font-bold">{{ inputEmail }}</span>
        </template>
      </I18nT>
      <BaseOtpInput
        v-if="!isLoading"
        class="mt-12"
        :digit-count="6"
        :errors="errors"
        @update:otp="token = $event"
        @reset-errors="errors = $event"
      />
      <div v-else class="flex justify-center">
        <span class="lc-loader">
          <i class="lc-loader__spin" />
        </span>
      </div>
      <div class="mt-24 flex flex-col items-center md:mt-16 md:items-start">
        <BaseButton
          class="text-md"
          color="secondary"
          variant="link"
          :disabled="isSendNewTokenBtnDisabled"
          @click="sendNewToken"
        >
          {{ $t('auth.sendMeNewCode') }}
        </BaseButton>
        <p v-if="sendNewTokenErrors" class="mt-4 font-medium text-error">
          {{ t('error.locked') }}
        </p>
      </div>
    </div>
  </div>
</template>
