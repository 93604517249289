<script setup lang="ts">
import type { Locale } from 'lc-services/types'
import groupFields from './form/emailForm'
import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import type { HouseSelected } from '../AuthModalWrapper.vue'

defineOptions({
  inheritAttrs: false,
})
const props = defineProps<{
  inputEmail: string
  houseSelected?: HouseSelected
}>()
const emits = defineEmits<{
  'on-success-email': [{ navigation: string; isEmailSignUp: boolean }]
  'update:email': [value: string]
  'handle-redirect': []
  'on-success-code': [
    { navigation: string; email: string; firstName: string; lastName: string },
  ]
}>()
defineSlots<{ 'step-1-before-form'(): any; 'step-1-after-form'(): any }>()

const { $lcServicesAuth } = useNuxtApp()
const { authController, authPresenter } = $lcServicesAuth
const { authenticated, signUp, credentials } = useAuth()
const { t, locale } = useI18n()
const { trackEvent } = useTracking()
const currentRoute = useRoute()
const { btnPrimary } = useButtons()

btnPrimary.attrs.blockFull = true
btnPrimary.text = computed(() => t('auth.continue'))
const groupFieldsForm = reactive(groupFields())

const initialData = reactive({
  email: props.inputEmail,
})

const handleLoginSuccess = async () => {
  if (authPresenter.vm.navigation === 'App') {
    trackEvent({ event: 'log_in', log_in_page: 'full_page' })
    emits('handle-redirect')
    return
  }
}

const handleLoginError = async () => {
  const dataCredentials = credentials.value
  if (!dataCredentials) return

  const data =
    typeof dataCredentials === 'object'
      ? dataCredentials
      : JSON.parse(dataCredentials)

  if (authPresenter.vm.navigation === 'SignUp') {
    emits('on-success-code', {
      navigation: authPresenter.vm.navigation,
      email: data.email,
      firstName: data.given_name || '',
      lastName: data.name || '',
    })

    return
  }
}

watch(
  authenticated,
  async (newValue) => {
    if (newValue === true) await handleLoginSuccess()
  },
  { immediate: true, deep: true },
)

watch(
  signUp,
  async (newValue) => {
    if (newValue === true) await handleLoginError()
  },
  { immediate: true, deep: true },
)

const mutation = (data: { email: string }) => ({
  request: async () => {
    await authController.sendTokenByEmail({
      email: data.email,
      locale: locale.value as Locale,
    })
    if (authPresenter.vm.errors.length) {
      return Promise.reject(authPresenter.vm.errors)
    }
    return { ...authPresenter.vm }
  },
})

const onSubmit = () => ({
  success: ({ msg, navigation }: { msg: string; navigation: string }) => {
    emits('on-success-email', {
      navigation,
      isEmailSignUp: msg === 'emailSignUp.success',
    })
  },
  error: (
    err: { errors: { fieldName: string; error: string }[] },
    setFieldError: (field: string, trad: string) => void,
  ) => {
    if (
      err?.errors[0]?.fieldName === 'email' &&
      err?.errors[0]?.error === 'locked'
    ) {
      setFieldError('email', t('error.locked'))
    }
    if (
      err?.errors[0]?.fieldName === 'email' &&
      err?.errors[0]?.error === 'invalid'
    ) {
      setFieldError('email', t('error.invalid_email'))
    }
  },
})

const formatHouseSelected = (houseSelected?: HouseSelected) => {
  return {
    houseId: houseSelected?.houseId,
    houseName: houseSelected?.houseName,
    iconic: houseSelected?.iconic,
    onlineBookingAvailable: !!houseSelected?.onlineBookingAvailable,
    price: houseSelected?.price,
  }
}

const handleLogin = async (provider: 'apple' | 'google') => {
  const formattedHouse = formatHouseSelected(props?.houseSelected)
  const queryParams = createQueryParams({
    ...formattedHouse,
    redirect: currentRoute.fullPath,
  })
  await navigateTo(`/api/oauth/redirect/${provider}?${queryParams}`, {
    external: true,
  })
}
</script>

<template>
  <div class="w-full">
    <slot name="step-1-before-form" />
    <BaseForm
      id-form="form-email"
      :active-error-scroll-to="false"
      :active-modal-errors="false"
      :active-modal-save-or-quit="false"
      :button-primary="btnPrimary"
      :disabled-primary-button="!Boolean(inputEmail)"
      :group-fields="groupFieldsForm"
      :initial-data="initialData"
      :mutation="mutation"
      :on-submit="onSubmit"
      container-button-class="mt-8 mb-4"
      @on-field-update="
        (_, value) => $emit('update:email', value.toLowerCase())
      "
    />
    <div class="flex items-center justify-center">
      <div class="h-px w-full bg-gray-200"></div>
      <span class="mx-4">{{ $t('auth.or') }}</span>
      <div class="h-px w-full bg-gray-200"></div>
    </div>
    <button
      class="my-4 flex w-full items-center justify-center rounded-md p-2"
      type="button"
      @click="handleLogin('apple')"
    >
      <img alt="" src="~/assets/svg/Apple.svg" class="svg pl-2" />
      <span class="ml-2 flex-1 font-bold">{{
        $t('auth.continueWithApple')
      }}</span>
      <span class="w-6" />
    </button>
    <button
      class="my-4 flex w-full items-center rounded-md p-2"
      type="button"
      @click="handleLogin('google')"
    >
      <img alt="" src="~/assets/svg/Google.svg" class="svg pl-2" />
      <span class="ml-2 flex-1 font-bold">{{
        $t('auth.continueWithGoogle')
      }}</span>
      <span class="w-6" />
    </button>
    <slot name="step-1-after-form" />
  </div>
</template>

<style scoped>
button {
  font-size: 14.5px;
  border: 1px solid #000;
  height: 50px;
  border-radius: 4px;
}
</style>
