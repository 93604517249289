import { InputCheckbox } from '~/components/BaseForm/core/primitives'
import {
  Civility,
  Firstname,
  Lastname,
  Phone,
} from '~/components/BaseForm/core/fields'

import type { CoreGroupFields } from '~/components/BaseForm/types'

export default (): CoreGroupFields => {
  const civility: typeof Civility = {
    ...Civility,
    attr: {
      ...Civility.attr,
      vertical: false,
      labelTranslateKey: 'field.civility',
    },
    options: [
      { value: 'mrs', labelTranslateKey: 'field.civilityFemale' },
      { value: 'mr', labelTranslateKey: 'field.civilityMale' },
      { value: 'unspecified', labelTranslateKey: 'field.civilityUnspecified' },
    ],
    containerInputClass: 'w-full px-2 mb-4',
  }

  const firstname: typeof Firstname = {
    ...Firstname,
    attr: {
      ...Firstname.attr,
      labelTranslateKey: 'field.firstName',
    },
    containerInputClass: 'w-full md:w-1/2 px-2 mb-4',
  }

  const lastname: typeof Lastname = {
    ...Lastname,
    attr: {
      ...Lastname.attr,
      labelTranslateKey: 'field.lastName',
    },
    containerInputClass: 'w-full md:w-1/2 px-2 mb-4',
  }

  const phone: typeof Phone = {
    ...Phone,
    attr: {
      ...Phone.attr,
      labelTranslateKey: 'field.phone',
    },
    containerInputClass: 'w-full md:w-1/2 px-2',
  }

  const newsletter: typeof InputCheckbox = {
    ...InputCheckbox,
    attr: {
      ...InputCheckbox.attr,
      labelTranslateKey: '',
      name: 'newsletter',
      rules: '',
      wrapperClass: 'mt-4 mb-2',
    },
    containerInputClass: 'w-full px-2',
  }

  const termsOfServiceSigned: typeof InputCheckbox = {
    ...InputCheckbox,
    attr: {
      ...InputCheckbox.attr,
      labelTranslateKey: '',
      name: 'termsOfServiceSigned',
      wrapperClass: 'mb-2',
    },
    containerInputClass: 'w-full px-2',
  }

  return {
    fields: [
      civility,
      firstname,
      lastname,
      phone,
      newsletter,
      termsOfServiceSigned,
    ],
    containerGroupFieldsClass: 'flex flex-wrap',
  }
}
